import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { getConcerningIssues } from '../../../Actions/analytics.actions';

import TherapistFeedbackCard from './therapistFeedbackCard';
import MoreInsightsCard from './moreInsightsCard';
import Loading from '../../../Components/loading';
import GlobalFilters from '../../../Components/globalFilters';
import { BreadCrumbContainer } from '../../../SharedStyles/BreadCrumbContainer';
import LocationPath from '../../../Components/locationPath';
import { paths } from '..';
import NewStyledSelect from '../../../SharedStyles/newStyledSelect';
import copyToClipboard from '../../../Util/clipboard';
import { useTranslation } from 'react-i18next';

const TherapistFeedbackPage = ({isRequired = false}) => {
  const [period, setPeriod] = useState('year');
  const [department, setDepartment] = useState(undefined);
  const [subDepartment, setSubDepartment] = useState(undefined);
  const [location, setLocation] = useState(0);

  const [compareprevious, setCompareprevious] = useState(0);
  const [sortByMoreInsights, setSortByMoreInsights] = useState('age');
  const { t } = useTranslation();
  const data = useSelector((store) => store.analytics?.concerningIssues?.data, undefined);
  const dispatch = useDispatch();

  const onPeriodChange = (p) => {
    setPeriod(p);
    // setLoadingDashboard(true);
    // dispatch(getConcerningIssues({ period: p, department_id: department })).finally(() => setLoadingDashboard(false));
  };

  const handleChange = (value, setValue) => {
    copyToClipboard(value, setValue);
  }; 

  const onSelectChange = (id) => {
    const departmentId = id;
    setDepartment(departmentId);
    // setLoadingDashboard(true);
    // dispatch(getConcerningIssues({ period, department_id: departmentId })).finally(() => setLoadingDashboard(false));
  };
  const onSubDeptChange = (id) => {
    setSubDepartment(id);
  };

  const onLocationChange = (id) => {
    const locationId = id;
    setLocation(locationId);
    // setLoadingDashboard(true);
    // dispatch(getConcerningIssues({ period, department_id: departmentId })).finally(() => setLoadingDashboard(false));
  };

  useEffect(() => {
    dispatch(
      getConcerningIssues({
        period,
        department_id: department,
        subdepartment_id: subDepartment,
        compare_previous: compareprevious,
        insight_type: sortByMoreInsights,
        country_id: location,
      }),
    );
  }, [dispatch, period, department, subDepartment,compareprevious, sortByMoreInsights, location]);

  return (
    <>
      {/* {data === undefined ? (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      ) : null} */}
      {/* <FiltersWrapper>
        <GlobalFilters period={period} onPeriodChange={onPeriodChange} onSelectChange={onSelectChange} />
      </FiltersWrapper> */}
      <BreadCrumbContainer>
        <LocationPath styles={{ margin: 0 }} multi paths={paths} pathName={t("analytics")} />
        <StyledDiv>
          <NewStyledSelect onChange = {handleChange} isRequired />

          <GlobalFilters
            period={period}
            onPeriodChange={onPeriodChange}
            department={department}
            onSelectChange={onSelectChange}
            onSubDeptChange={onSubDeptChange}
            onLocationChange={onLocationChange}
            />
        </StyledDiv>
      </BreadCrumbContainer>

      <MobileMargin />
      <div className="copyFullImage">
        <div className="copyChartElement">
          <TherapistFeedbackCard
            period={period}
            setCompareprevious={setCompareprevious}
            toolTipText={t("therapistFeedbackTooltip")}
          />
        </div>
        <div className="copyDemographicInsights">
          <MoreInsightsCard
            setSortByMoreInsights={setSortByMoreInsights}
            sortByMoreInsights={sortByMoreInsights}
            toolTipText={t("insightsPageTooltip")}
          />
        </div>
      </div>
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;

// const HoverWrapper = styled.div`
//   position: absolute;
//   z-index: 1000;
//   background: #ffffffc7;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
// `;

const MobileMargin = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 0;

  @media screen and (max-width: 715px) {
    margin-bottom: 30px;
  }
`;

const FiltersWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 18px;

  @media screen and (max-width: 715px) {
    top: 60px;
    left: 25px;
  }
`;

export default memo(TherapistFeedbackPage);
