import React, { memo, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { debounce, pick } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { Form, Select, Collapse, message, Input } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { getQuestionnaire, getSurveyQuestions, saveSurvey } from '../../../Actions/survey.actions';
import { useTranslation } from 'react-i18next';
import PreviewSurvey from './previewSurvey';
import SurveyNextStep from './surveyNextStep';
import SurveyForm from './surveyForm';
import SurveyFinalStep from './SurveyFinalStep';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Loading from '../../../Components/loading';
import moment from 'moment';
import LocationPath from '../../../Components/locationPath';
import { paths } from '..';
import { BreadCrumbContainer } from '../../../SharedStyles/BreadCrumbContainer';
import { getEmployeeList, getProfile } from '../../../Actions/company.actions';

const initialEmail = `
<span> Hi [NAME] ,</span>
<p>We are just checking in, to see how you are feeling!</p>
<p>We have created a survey to check in to see how you are doing. This survey is focused on Employee Happiness!</p>
<p>When you have a moment, please take some time out of your day to complete the survey and let us know how you feel!</p>
<h4><a href='https://example.com' target="_self"><strong>Take Survey</strong></a></h4>
<p style="text-align:center;">Kind regards,</p>
<p style="text-align:center;">SupportRoom</p>
`;

const CreateSurvey = () => {
  const { t } = useTranslation();
  const query = new URLSearchParams(window.location.search);
  const surveyId = query.get('id');
  const questions = useSelector((store) => store.survey.questions);
  const departments = useSelector((store) => store.company.departments);
  const employeeList = useSelector((store) => store.company.employeeList);
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(false);

  const [formData, setFormData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [date, setDate] = useState(null);
  const [customQuestions, setCustomQuestions] = useState([
    {
      ques: '',
      answerType: 'regular',
    },
  ]);
  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(initialEmail))),
  );
  const [checked, setChecked] = useState(false);
  const [previewFreeText, setPreviewFreeText] = useState([]);

  const [initialValues, SetInitialValues] = useState({
    survey_name: '',
    description: '',
    send_to: '',
    subject:  t("supportRoomSurvey"),
  });
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles) => {
    setFormData((prevState) => ({
      ...prevState,
      logo: acceptedFiles.length ? { file: acceptedFiles[0], preview: acceptedFiles[0].preview } : null,
    }));
    setFileError(false);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    onDropRejected: (err) => {
      if (err?.[0].errors?.[0].code === 'file-too-large') {
        message.error( t("fileIsTooLargeThankb"));
      }
    },
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    maxSize: 200000,
  });

  const goToDashboard = () => history.push('/dashboard');
  // const toggleModal = () => setIsModalVisible((state) => !state);
  const submitHandler = (submitData, type = 'submit') => {
    const customQuestions = Object.keys(submitData)
      .filter((key) => {
        return key.indexOf('freetext_questions') === 0;
      })
      .reduce((obj, key) => {
        const newData = [...obj];
        newData.push(...submitData[key]);
        return newData;
      }, []);

    const questionsIds = Object.keys(submitData)
      .filter((key) => key.indexOf('question') === 0)
      .reduce((allQuestionsIds, k) => {
        const newData = [...allQuestionsIds];
        newData.push(...submitData[k]);
        return newData;
      }, []);
    if (files.length === 0 && !surveyId) {
      setFileError(true);
      return;
    }
    if (!convertToRaw(editorState.getCurrentContent()).blocks.some(({ text }) => text)) {
      return;
    }
    if (type === 'submit') {
      setSaveLoading(true);
    } else {
      setDraftLoading(true);
    }

    const rawContent = convertToRaw(editorState.getCurrentContent());
    let htmlContent = draftToHtml(rawContent);
    htmlContent = htmlContent.replace(/<a\s+href="[^"]*"/g, "<a href='[LINK]'");
    const employeeIds = submitData?.employee_ids?.map((item)=> {
      let splitted = item.value?.split('--')
      return +splitted[1]
    })
    if (questionsIds.length || customQuestions.length) {
      const newData = pick(submitData, ['survey_name', 'description', 'send_to', 'subject']);

      newData.question_ids = questionsIds;
      newData.employee_ids = employeeIds;
      newData.type = type;
      newData.logo = formData?.logo?.file;
      newData.email_body = htmlContent;
      newData.freetext_questions = customQuestions.filter((customQuestion) => customQuestion?.question);

      if (checked) {
        newData.schedule_date = moment(date).format('YYYY-MM-DD hh:mm:ss');
      }
      dispatch(saveSurvey(newData, surveyId, checked))
        .then(() => {
          dispatch(getProfile()).then(() => {
            message
              .success(
                checked
                  ? `${t("surveyCreatedSuccessfully")} ${newData.schedule_date}`
                  : `${t("surveyCreatedAnd")} ${newData.type === 'draft' ? t("andSavedAsDraft") : t('sent')} ${t("successfully")}`,
              )
              .then();
            if (type === 'submit') {
              setSaveLoading(false);
            } else {
              setDraftLoading(false);
            }
            history.push('/survey/overview');
          });
        })
        .catch(() => {
          if (type === 'submit') {
            setSaveLoading(false);
          } else {
            setDraftLoading(false);
          }
        });
    } else {
      if (type === 'submit') {
        setSaveLoading(false);
      } else {
        setDraftLoading(false);
      }
      message.warning( t("pleaseSelectAtLeastOneQuestion")).then();
    }
  };

  const onSubmit = (submitData) => {
    submitHandler(submitData);
  };

  const onValuesChange = (values, allValues) => {
    setFormData((state) => ({ ...state, ...values }));
    setPreviewFreeText(allValues?.freetext_questions || []);
  };

  const debouncedValuesOnChange = debounce(onValuesChange, 300);

  const removeFile = () => {
    setFiles([]);
    setFormData((prevState) => ({ ...prevState, logo: null }));
  };

  const checkAllFieldFilled = (fields, filelength) => {
    if ((fields.send_to || fields.employee_ids) && fields.survey_name?.trim() && fields.description?.trim() && (filelength || uploadedLogo)) {
      return false;
    }
    return true;
  };

  const thumbs = files.map((file) => (
    <Thumb key={file.name}>
      <RemoveThumb>
        <CloseCircleOutlined onClick={removeFile} />
      </RemoveThumb>
      <ThumbInner>
        <Img alt="image" src={file.preview} />
      </ThumbInner>
    </Thumb>
  ));

  useEffect(() => {
    dispatch(getSurveyQuestions());
    dispatch(getEmployeeList());
  }, [dispatch]);

  useEffect(() => {
    if (!surveyId) return;
    getQuestionnaire(surveyId)()
      .then(({ data }) => {
        const newInitialValues = {
          survey_name: data.name,
          description: data.description,
          send_to: data.company_department_id === 0 ? '0' : data.company_department_id,
          subject: data.subject ||  t("supportRoomSurvey"),
        };

        const selectedQuestions = data.questions.reduce((acc, curr) => {
          const key = `question-${curr.category_id}`;

          if (!curr.custom) {
            if (acc[key]) {
              acc[key].push(curr.id);
            } else {
              acc[key] = [curr.id];
            }
          }
          return acc;
        }, {});

        const selectedRegularCustomQuestions = data.questions.reduce((acc, curr) => {
          let newObject = {
            question: curr.question,
            type: curr.type,
          };
          if (curr.custom === 1) {
            acc.push(newObject);
          }
          return acc;
        }, []);

        // const selectedCustomQuestions = data.freetext_questions?.map((el) => {
        //   return {
        //     question: el.question,
        //     type: el.type,
        //   };
        // });

        const freetextpayload = {
          freetext_questions: [...selectedRegularCustomQuestions],
        };

        SetInitialValues({ ...newInitialValues, ...selectedQuestions, ...freetextpayload });
        setFormData({ ...newInitialValues, ...selectedQuestions, ...freetextpayload });
        setPreviewFreeText(freetextpayload.freetext_questions);
        setUploadedLogo(data.logo_url);
        setEditorState(
          EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data.email_body))),
        );
      })
      .catch((err) => console.log(err));
  }, [surveyId]);

  // const formatDate = new Date(date?.toLocaleString());

  if (surveyId && !initialValues.survey_name) {
    return <Loading />;
  }
  return (
    <div>
      <BreadCrumbContainer>
        <LocationPath multi paths={paths} pathName={t("heartbeat")} />
      </BreadCrumbContainer>
      <PreviewSurvey
        data={formData}
        questions={questions}
        isModalVisible={isModalVisible}
        toggleModal={() => setIsModalVisible(false)}
        onSubmit={onSubmit}
        uploadedLogo={uploadedLogo}
        setStep={setStep}
        previewFreeText={previewFreeText}
      />
      <FormWrapper>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          autoComplete="off"
          onValuesChange={debouncedValuesOnChange}
          initialValues={initialValues}
          // onFieldsChange={(_, val) => console.log(val)}
        >
          <div
            style={{
              display: step === 0 ? 'block' : 'none',
            }}
          >
            <SurveyForm
              departments={departments}
              employeeList={employeeList}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              isDragActive={isDragActive}
              isDragReject={isDragReject}
              thumbs={thumbs}
              fileError={fileError}
              checkAllFieldFilled={checkAllFieldFilled}
              goToDashboard={goToDashboard}
              form={form}
              files={files}
              setStep={setStep}
              isEdit={!!surveyId}
              uploadedLogo={
                uploadedLogo ? (
                  <Thumb key="logo">
                    <RemoveThumb>
                      <CloseCircleOutlined onClick={() => setUploadedLogo(null)} />
                    </RemoveThumb>
                    <ThumbInner>
                      <Img alt="image" src={uploadedLogo} />
                    </ThumbInner>
                  </Thumb>
                ) : null
              }
            />
          </div>
          <div
            style={{
              display: step === 1 ? 'block' : 'none',
            }}
          >
            <SurveyNextStep
              setStep={setStep}
              draftLoading={draftLoading}
              saveLoading={saveLoading}
              submitHandler={submitHandler}
              form={form}
              toggleModal={() => setIsModalVisible(true)}
              questions={questions}
              initialValues={initialValues}
              customQuestions={customQuestions}
              setCustomQuestions={setCustomQuestions}
            />
          </div>
          <div
            style={{
              display: step === 2 ? 'block' : 'none',
            }}
          >
            <SurveyFinalStep
              setStep={setStep}
              draftLoading={draftLoading}
              saveLoading={saveLoading}
              submitHandler={submitHandler}
              form={form}
              toggleModal={() => setIsModalVisible(true)}
              questions={questions}
              editorState={editorState}
              setEditorState={setEditorState}
              date={date}
              setDate={setDate}
              checked={checked}
              setChecked={setChecked}
            />
          </div>
        </Form>
      </FormWrapper>
    </div>
  );
};

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCollapse = styled(Collapse)`
  border: none;
  background-color: transparent;
  margin: 10px 0;
  .ant-collapse-header {
    font-size: 16px;
    color: #575d7a;
    font-weight: 500;
  }
  .ant-collapse-item {
    background-color: #fff;
    margin: 14px 0;
    border-radius: 15px !important;
    padding: 5px 0;
  }
  label {
    font-size: 16px !important;
    font-weight: normal !important;
    padding: 0 !important;
  }
`;
export const FormWrapper = styled.div`
  .ant-form-item-required,
  label {
    font-size: 17px;
    color: #575d7a;
    font-weight: 500;
    padding: 5px 0 3px 0;
  }
  .ant-input {
    height: 40px;
    &::placeholder {
      color: #828181;
    }
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }
  .ant-btn {
    &:hover,
    &:active {
      color: #00c8a0;
      border-color: #00c8a0;
    }
  }

  .ant-collapse-header {
    font-weight: 500;
    font-size: 16px;
    color: #575d7a;
  }
`;

export const CheckboxText = styled.span`
  font-size: 16px;
  color: #575d7a;
`;

export const TextName = styled.div`
  font-size: 17px;
  color: #575d7a;
  font-weight: 500;
  padding: 25px 0 3px 0;
`;

export const ButtonsWrapper = styled.div`
  // width: 240px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const UploadDashedBorder = styled.div`
  padding: 30px 60px;
  border: 2px dashed #d9d9d9;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ isDragActive }) => isDragActive && '#b3dbb1'};
  background: ${({ isDragReject }) => isDragReject && '#dbb1b1'};
`;

export const UploadContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const BrowsFiles = styled.div`
  color: #575d7a;
  font-size: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 20px;
  cursor: pointer;
`;

const Thumb = styled.div`
  position: relative;
  margin-top: 10px;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`;
const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;
const Img = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

const RemoveThumb = styled.div`
  position: absolute;
  top: -7px;
  left: -7px;
  cursor: pointer;

  svg {
    font-size: 18px;
    path {
      fill: red;
    }
  }
`;

export const TextAreaCounterWrapper = styled(Input.TextArea)`
  ::after {
    width: auto !important;
  }
`;

export const TextAreaErrorPosition = styled.div`
  .ant-form-item-explain-error {
    margin-left: 60px !important;
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    border: 1px solid #d9d9d9 !important;
    .ant-select-selection-placeholder {
      color: #828181 !important;
    }
  }
`;

export default memo(CreateSurvey);
