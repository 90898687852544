import React, { memo, useEffect, useState } from 'react';
import MoreInsightsGraph from '../../../Components/moreInsightsGraph';
import FullCard, { ContentWrapper } from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';
import CardInfoHeader from '../../../Components/cardInfoHeader';
import { useTranslation } from 'react-i18next';
import api from '../../../Util/api';

const MoreInsightsCard = ({ period, departmentId, subDepartmentId, location, setLoading }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState({});
  const [responseType, setResponseType] = useState(false);
  const [sortByMoreInsights, setSortByMoreInsights] = useState('age');

  const onSelectResponseChange = (id) => {
    setResponseType(id);
  };
  const responseMapping = {
    age: 'employees within the age range of',
    gender: '',
  };

  useEffect(() => {
    const payload = {
      period,
      department_id: departmentId,
      subdepartment_id: subDepartmentId,
      // compare_previous: compareprevious,
      insight_type: sortByMoreInsights,
      positive: responseType,
      country_id: location,
    };

    const getSurveyResponseData = async () => {
      try {
        setLoading((prevState) => prevState + 1);
        const response = await api.analytics.getLeadingIssues(payload);
        setResponse(response?.data?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading((prevState) => prevState - 1);
      }
    };
    getSurveyResponseData();
  }, [period, departmentId, subDepartmentId, location, responseType, sortByMoreInsights]);
  const onSelect = (filters) => {
    setSortByMoreInsights(filters.filter_type);
  };
  return (
    <FullCard>
      <CardHeader
        name={t("demographicInsights")}
        options={{ showGender: true }}
        onSelect={onSelect}
        showResponseType
        onSelectResponseChange={onSelectResponseChange}
        customWidth="400px"
        toolTipText={t("insightsPageTooltip")}
      />
      <ContentWrapper>
        <CardInfoHeader
          data={Object.keys(response?.more_insights || []).map((key) => key)}
          count={response?.more_insights_counts || {}}
          responses={response?.more_insights ? response?.more_insights : null}
          responseMessage={responseMapping[sortByMoreInsights]}
        />
        <MoreInsightsGraph
          data={
            response?.more_insights
              ? Object.fromEntries(Object.entries(response?.more_insights).filter(([_, v]) => Object.keys(v)?.length))
              : null
          }
        />
      </ContentWrapper>
    </FullCard>
  );
};

export default memo(MoreInsightsCard);
