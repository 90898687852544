import {
  GET_COMPANY_MOOD,
  GET_LEADING_ISSUES,
  GET_PRIORITY_ISSUES,
  GET_TEXT_ANALYTICS,
  GET_CONCERNING_ISSUES,
  LOGOUT,
  GET_ACTIVITY_LEVEL,
  GET_TEXT_ANALYTICS_INSIGHTS,
  GET_LEADING_ISSUES_INSIGHTS,
  GET_COMPANY_MOOD_INSIGHTS,
  GET_PRIORITY_ISSUES_INSIGHTS,
  GET_UNHAPPY_DATA,
} from '../Constants/actionTypes';

const initialState = {
  text: null,
  companyMood: null,
  leadingIssues: null,
  priorityIssues: null,
  unhappyData: null,
  concerningIssues: null,
  activityLevel: null,
};

export default function analyticsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEXT_ANALYTICS:
      return {
        ...state,
        text: action.payload,
      };
    case GET_TEXT_ANALYTICS_INSIGHTS:
      return {
        ...state,
        text: {
          ...state.text,
          data: {
            ...state.text.data,
            more_insights: action.payload.data?.more_insights,
          },
        },
      };
    case GET_COMPANY_MOOD:
      return {
        ...state,
        companyMood: action.payload,
      };
    case GET_COMPANY_MOOD_INSIGHTS:
      return {
        ...state,
        companyMood: {
          ...state.companyMood,
          data: {
            ...state.companyMood.data,
            more_insights: action.payload.data?.more_insights,
          },
        },
      };
    case GET_LEADING_ISSUES:
      return {
        ...state,
        leadingIssues: action.payload,
      };
    case GET_LEADING_ISSUES_INSIGHTS:
      return {
        ...state,
        leadingIssues: {
          ...state.leadingIssues,
          data: {
            ...state.leadingIssues.data,
            more_insights: action.payload.data?.more_insights,
          },
        },
      };
    case GET_PRIORITY_ISSUES:
      return {
        ...state,
        priorityIssues: action.payload,
      };
    case GET_UNHAPPY_DATA:
      return {
        ...state,
        unhappyData: action.payload,
      };
    case GET_PRIORITY_ISSUES_INSIGHTS:
      return {
        ...state,
        priorityIssues: {
          ...state.priorityIssues,
          data: {
            ...state.priorityIssues.data,
            more_insights: action.payload.data?.more_insights,
          },
        },
      };
    case GET_CONCERNING_ISSUES:
      return {
        ...state,
        concerningIssues: action.payload,
      };
    case GET_ACTIVITY_LEVEL:
      return {
        ...state,
        activityLevel: action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
