import React, { memo, useRef, useState } from 'react';
import styled from 'styled-components';
import { CheckCircleOutlined } from '@ant-design/icons';
import checkCircleGreen from '../Assets/Analysis/checkCircleGreen.png';
import checkCirclePurple from '../Assets/Analysis/checkCirclePurple.png';
import PlayImage from '../Assets/Analysis/play.png';
import UgradeModal from './UgradeModal';

const LeaderCard = ({
  data = {
    name: '',
    text: '',
    status: '',
    list: [],
    image: '',
    video: null,
  },
  width,
  height,
}) => {
  const [modal, setModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const vidRef = useRef(null);

  return (
    <CardLayout>
      <HeadingWrapper>
        <HeadlineDiv>
          <MiniCardHeadline>{data?.name}</MiniCardHeadline>

          {data?.status === 'Active' && <StatusWrapper>{data?.status}</StatusWrapper>}
        </HeadlineDiv>
        {data?.status !== 'Active' && (
          <div>
            <UpgradeButton onClick={() => setModal(true)}>{data?.status}</UpgradeButton>
          </div>
        )}
      </HeadingWrapper>

      {data?.status === 'Get a Quote' ? (
        <div>
          <Divider />
          <ImageLayout>
            <ImageWrapper quote={true} src={data?.image} />
          </ImageLayout>
          <DescriptionText quote={true}>{data?.text}</DescriptionText>
        </div>
      ) : (
        <>
          <ImageView>
            {/* {data?.video ?
              <>
                <VideoWrapper ref={vidRef}
                  onEnded={() => setIsPlaying(false)}
                >
                  <source src={data?.video} type="video/mp4" />
                </VideoWrapper>
                {!isPlaying &&
                  <ImageOverlay src={PlayImage}
                    onClick={() => {
                      setIsPlaying(true);
                      vidRef?.current?.play()
                    }}
                  />
                }
              </>
              : */}
            <ImageWrapper src={data?.image} />
            {/* } */}
          </ImageView>
          <DescriptionText>{data?.text}</DescriptionText>
          <ListDiv>
            {data?.list.map((val) => (
              <LineDiv>
                {/* <CheckCircleOutlined style={{ color: '#491F69', fontSize: '15px' }} /> */}
                <CheckImage src={data?.status === 'Active' ? checkCircleGreen : checkCirclePurple} />
                <LineText>{val}</LineText>
              </LineDiv>
            ))}
          </ListDiv>
        </>
      )}

      {/* <CheckCircleOutlined style={{color: true ? '#491F69' : '#57BF9A', fontSize: '4px'}} /> */}
      {modal ? (
        <UgradeModal
          type={data?.status === 'Get a Quote' ? 'getQuote' : 'upgrade'}
          keyName={data?.key}
          isOpen={modal}
          closeModal={() => setModal(false)}
        />
      ) : null}
    </CardLayout>
  );
};

const Card = styled.div`
  background: ${({ theme }) => theme.white};
  min-width: 380px;
  border-radius: 5px;
  padding: 22px;
  box-shadow: rgba(0, 0, 0, 0.13) 0 1px 4px;
`;

const CardLayout = styled(Card)`
  position: relative;
  flex:1;
  display: flex;
  flex-direction: column;

  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px 10px 15px;
  box-shadow: 2px 4px 5px #cccccc91;
`;

const MiniCardHeadline = styled.span`
  font-size: 17px;
  font-weight: 600;
  text-align: left;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #131523;
  padding-bottom: 5px;
  justify-content: space-between;
`;

const HeadlineDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #131523;
  padding-bottom: 10px;
  justify-content: space-between;
`;

const ListDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 16px;
  color: #131523;
`;
const LineDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const LineText = styled.li`
  list-style-type: none;
  font-size: 12px;
  margin-left: 10px;
`;

const UpgradeButton = styled.button`
  display: block;
  background-color: #472366;
  padding: 7px 10px;
  color: #fff;
  border-radius: 6px;
  // text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  align-self: flex-end;
  width: 121px;
  cursor: pointer;
`;

const Divider = styled.div`
  margin-top: 5px;
  border-top: 1px solid ${({ theme }) => theme.greyLight};
`;

const ImageView = styled.div`
  position: relative;
  display: inline-block;
`;

const ImageLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
const ImageWrapper = styled.img`
  // height: ${({ quote }) => (quote ? '175px' : '150px')};
  width: ${({ quote }) => (quote ? '140px' : '100%')};
  object-fit: contain;
  margin: 5px 0px 0px;
`;
const VideoWrapper = styled.video`
  height: ${({ quote }) => (quote ? '175px' : '150px')};
  width: ${({ quote }) => (quote ? '140px' : '100%')};
  margin: 5px 0px 0px;
`;

const CheckImage = styled.img`
  height: 16px;
  width: 16px;
`;

const ImageOverlay = styled.img`
  height: 40px;
  width: 60px;
  position: absolute;
  top: 40%;
  left: 42%;
  opacity: 0.8;
`;

const DescriptionText = styled.p`
  text-align: left;
  color: black;
  font-size: ${({ quote }) => (quote ? '15px' : '14px')};
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 5px;
  white-space: pre-line;
  cursor: pointer;
`;

const StatusWrapper = styled.span`
  display: block;
  background-color: #49bf9c;
  padding: 3px 10px;
  color: #fff;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 14px;
`;

export default memo(LeaderCard);
