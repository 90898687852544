import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getTextAnalytics } from '../../../Actions/analytics.actions';

import TextAnalyticsCard from './textAnalyticsCard';
import MoreInsightsCard from './moreInsightsCard';
import Loading from '../../../Components/loading';
import GlobalFilters from '../../../Components/globalFilters';
import { BreadCrumbContainer } from '../../../SharedStyles/BreadCrumbContainer';
import LocationPath from '../../../Components/locationPath';
import { paths } from '..';
import ExportTextAnalytics from './exportTextAnalytics';
import { useTranslation } from 'react-i18next';

const PriorityIssuesPage = () => {
  const { t } = useTranslation();
  console.log('🚀 ~ file: textAnalyticsPage.js ~ line 25 ~ PriorityIssuesPage ~ period');
  const [period, setPeriod] = useState('year');
  const [departmentId, setDepartmentId] = useState('0');
  const [subDepartmentId, setSubDepartmentId] = useState('0');
  const [location, setLocation] = useState('0');

  const [compareprevious, setCompareprevious] = useState(0);
  const [sortByMoreInsights, setSortByMoreInsights] = useState('age');
  const [isLoading, setIsLoading] = useState(false);

  const data = useSelector((store) => store.analytics?.text?.data);
  const dispatch = useDispatch();

  const onPeriodChange = (p) => {
    setPeriod(p);
    // setLoadingDashboard(true);
    // dispatch(getTextAnalytics({ period: p, department_id: departmentId })).finally(() => setLoadingDashboard(false));
  };

  const onSelectChange = (id) => {
    const departmentId = id;
    setDepartmentId(departmentId);
    // setLoadingDashboard(true);
    // dispatch(getTextAnalytics({ period, department_id: departmentId })).finally(() => setLoadingDashboard(false));
  };
  const onSubDeptChange = (id) => {
    setSubDepartmentId(id);
  };

  const onLocationChange = (id) => {
    const locationId = id;
    setLocation(locationId);
    // setLoadingDashboard(true);
    // dispatch(getTextAnalytics({ period, department_id: departmentId })).finally(() => setLoadingDashboard(false));
  };

  useEffect(() => {
    dispatch(
      getTextAnalytics({
        period,
        department_id: departmentId,
        subdepartment_id: subDepartmentId,
        compare_previous: compareprevious,
        insight_type: sortByMoreInsights,
        country_id: location,
      }),
    );
  }, [dispatch, period, departmentId, subDepartmentId, compareprevious, sortByMoreInsights, location]);

  return (
    <>
      {isLoading && (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      )}

      {/* {data === undefined ? (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      ) : null} */}
      {/* <FiltersWrapper>
        <GlobalFilters period={period} onPeriodChange={onPeriodChange} onSelectChange={onSelectChange} />
      </FiltersWrapper> */}
      <BreadCrumbContainer>
        <LocationPath styles={{ margin: 0 }} multi paths={paths} pathName={t("analytics")} />
        <StyledDiv>
          <ExportTextAnalytics />
          <GlobalFilters
            period={period}
            onPeriodChange={onPeriodChange}
            department={departmentId}
            onSelectChange={onSelectChange}
            onSubDeptChange={onSubDeptChange}
            onLocationChange={onLocationChange}
          />
        </StyledDiv>
      </BreadCrumbContainer>
      <MobileMargin />
      <div className="copyFullImage">
        <div className="copyChartElement">
          <TextAnalyticsCard period={period} setCompareprevious={setCompareprevious} />
        </div>
        <div className="copyDemographicInsights">
          <MoreInsightsCard setSortByMoreInsights={setSortByMoreInsights} sortByMoreInsights={sortByMoreInsights} />
        </div>
      </div>
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;

const FiltersWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 18px;

  @media screen and (max-width: 715px) {
    top: 60px;
    left: 25px;
  }
`;

const MobileMargin = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 0;

  @media screen and (max-width: 715px) {
    margin-bottom: 30px;
  }
`;

const HoverWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  background: #ffffff;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export default memo(PriorityIssuesPage);
