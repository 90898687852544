import leaderAnalytics from '../../Assets/Analysis/leaderAnalytics.jpeg';
import nixy from '../../Assets/Analysis/nixy.jpeg';
import heartbeat from '../../Assets/Analysis/heartbeat.jpeg';
import supportFit from '../../Assets/Analysis/supportFit.jpeg';
import community from '../../Assets/Analysis/community.jpeg';
import image6 from '../../Assets/Analysis/image6.png';
import nixyVideo from '../../Assets/Analysis/nixy.mp4';
import supportFitVideo from '../../Assets/Analysis/supportfit.mp4';
import communityVideo from '../../Assets/Analysis/community.mp4';

export const LeaderData = [
  {
    name: 'Leaders Analytics',
    text: 'SupportRoom’s Leader Analytics Platform provides anonymised data-driven insights that allow you to monitor and identify key issues impacting the wellness, performance, and growth of your team.',
    status: 'Active',
    list: [
      'Strategies focused on micro audiences',
      'Guide your management team at all levels to improve the wellness',
      'Driving an increase in productivity and profitability',
    ],
    image: leaderAnalytics,
  },
  {
    key: 'nixy',
    name: 'NIXY',
    text: 'Wellness support that truly understands women. NIXY improves the health & wellness of women in the workplace through tailored and professional online support.',
    status: 'Upgrade now',
    list: [
      'Let Women in your team flourish in their roles.',
      'Enable them to not just ask for the help, but get it.',
      'Join the leaders empowering Women today.',
    ],
    image: nixy,
    video: nixyVideo,
  },
  {
    key: 'only_heartbeat',
    name: 'Heartbeat',
    text: 'Clear results come from asking the right questions to the right people. With Heartbeat, create personalised surveys to improve employee engagement and performance within your organization.',
    status: 'Upgrade now',
    list: ['Set up & Create', 'Personalization & Various Pillars', 'Immediate insights'],
    image: heartbeat,
  },
  {
    key: 'supportfit',
    name: 'SupportFit',
    text: 'Workplace physical wellness, that improves sleep, nutrition & movement.',
    status: 'Upgrade now',
    list: [
      'Connect wearable and mobile devices',
      'Monitors nutrition, movement and sleep',
      'Data driven insights',
      'Monitors improvements in physical wellness',
      'Weekly physical health targets',
    ],
    image: supportFit,
    video: supportFitVideo,
  },
  {
    key: 'community',
    name: 'Community',
    text: 'Allows employees to connect with colleagues in a more personal and meaningful way. Whether joining an existing group or creating a new one, employees can engage in open or closed communities based on shared interests or experiences.',
    status: 'Upgrade now',
    list: [
      'Offer a closer way to connect and support each other',
      'Fostering a sense of belonging within the organization',
      'Providing flexibility and comfort in communication',
    ],
    image: community,
    video: communityVideo,
  },
  {
    key: 'get_a_quate',
    name: 'Speak to your AM',
    text: `Have any questions or need extra support? \n\n Contact your dedicated account manager now who will assist you every step of the way!`,
    status: 'Get a Quote',
    image: image6,
  },
];

export const updateStatus = (profileData) => {
  return LeaderData.map((item) => {
    if (item?.key && profileData.hasOwnProperty(item?.key) && profileData[item?.key]) {
      return { ...item, status: 'Active' };
    }
    return item;
  });
};
