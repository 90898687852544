import React, { useEffect, useState } from 'react';
import api from '../../../Util/api';
import WorkLoadTagGard from './TagGraph';

const LeadingIssuesQuestions = ({ period, departmentId, subDepartmentId, location, setLoading }) => {
  const [response, setResponse] = useState({});

  useEffect(() => {
    const payload = {
      period,
      department_id: departmentId,
      subdepartment_id: subDepartmentId,
      compare_previous: 0,
      positive: 'all',
      country_id: location
    };

    const getSurveyResponseData = async () => {
      try {
        setLoading((prevState) => prevState + 1);
        const response = await api.analytics.getLeadingIssues(payload);
        setResponse(response?.data?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading((prevState) => prevState - 1);
      }
    };
    getSurveyResponseData();
  }, [period, departmentId, subDepartmentId, location]);

  return (
    <div className="copyChartDesciption">
      {response?.current &&
        Object.keys(response.current).map((key) => (
          <WorkLoadTagGard key={key} name={response.current[key].title} data={response.current[key]} />
        ))}
    </div>
  );
};

export default LeadingIssuesQuestions;
