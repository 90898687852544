import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import FullCard, { ContentWrapper, LeftWrapper, RightWrapper } from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';
import { useTranslation } from 'react-i18next';
import DoughnutChart from '../../../Components/doughnutChart';
import InfoContainer from '../../../Components/InfoContainer';

const HappinessCard = ({ period, setCompareprevious }) => {
  const data = useSelector((store) => store.analytics?.unhappyData);
  const { t } = useTranslation();
  const doughnutParsedData = useMemo(() => {
    return data ? data.map((item) => ({ title: item.reason, percentage: item.count })) : [];
  }, [data]);

  return (
    <FullCard>
      <CardHeader
        name={t("Unhappiness Insights")}
        noCompare={true}
        customWidth="400px"
        showToolTip={false}
      // toolTipText={t("priorityIssuesTooltip")}
      />
      {/* <SingleViewPieWithDescription data={data} loader={false}/> */}
      <ContentWrapper>
        <LeftWrapper>
          <DoughnutChart data={doughnutParsedData} width={300} height={300} />
        </LeftWrapper>
        <RightWrapper>
          {doughnutParsedData?.map((item, index) => {
            return (
              <InfoContainer
                key={item?.title}
                item={item}
                index={index}
              />
            )
          }
          )}
        </RightWrapper>
      </ContentWrapper>
    </FullCard>
  );
};

export default memo(HappinessCard);
