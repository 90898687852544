import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getActivityLevel } from '../../../Actions/analytics.actions';

import ActivityLevelCard from './activityLevelCard';
import MoreInsightsWorldMap from './moreInsightsWorldMap';
import Loading from '../../../Components/loading';
import GlobalFilters from '../../../Components/globalFilters';
import { BreadCrumbContainer } from '../../../SharedStyles/BreadCrumbContainer';
import LocationPath from '../../../Components/locationPath';
import { paths } from '..';
import copyToClipboard from '../../../Util/clipboard';
import ExportMoreInsightsWorldMap from './exportMoreInsightsWorldMap';
import { message } from 'antd';
import { copyImageToClipboard } from 'copy-image-clipboard';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import NewStyledSelect from '../../../SharedStyles/newStyledSelect';

const ActivityLevelPage = ({isRequired=false}) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState('year');
  const [department, setDepartment] = useState(undefined);
  const [subDepartment, setSubDepartment] = useState(undefined);
  const [location, setLocation] = useState(0);
  const [compareprevious, setCompareprevious] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const data = useSelector((store) => store.analytics?.activityLevel?.data, undefined);

  const dispatch = useDispatch();

  const onPeriodChange = (p) => {
    setPeriod(p);
  };

  const onSelectChange = (id) => {
    const departmentId = id;
    setDepartment(departmentId);
  };
  const onSubDeptChange = (id) => {
    setSubDepartment(id);
  };

  const onLocationChange = (id) => {
    const locationId = id;
    setLocation(locationId)
  };

  const key = 'copyToClipboardLoading';
  const successKey = 'copyToClipboardSuccess';
  const newCopyToClipboard = async (value, setValue) => {
    message.destroy(key);
    message.destroy(successKey);
    const loadingTimer = setTimeout(() => {
      message.loading({ content: t("copyingToClipboardPleaseWait"), key });
    }, 350);
    setIsLoading(true);

    const ChartElement = document.getElementById('moreInsightsWorldMap');
    ChartElement.style.display = 'none';
    const exportChartElement = document.getElementById('exportMoreInsightsWorldMap');
    exportChartElement.style.display = 'block';

    const exportElement = document.getElementsByClassName(value);

    console.log(exportElement, 'exportElement');
    const exportElementArr = [];
    for (let elem of exportElement) {
      exportElementArr.push(html2canvas(elem, { scale: 2 }));
    }
    const response = await axios.all(exportElementArr);
    console.log(response, 'response');

    for (let elem of response) {
      let imgData = elem.toDataURL('image/png', 1.0);
      try {
        await copyImageToClipboard(imgData);
        setIsLoading(false);

        ChartElement.style.display = 'block';
        exportChartElement.style.display = 'none';
        message.destroy(key);
        clearTimeout(loadingTimer);
        setTimeout(() => {
          message.success({ content: t("imageCopiedToClipboard"), key: successKey });
          setValue()
        }, 350);
      } catch (e) {
        alert(e, 'error');
      }
    }
  };

  const handleChange = (value, setValue) => {
    if (value === 'copyFullImage') {
      newCopyToClipboard(value, setValue);
    } else if (value === 'copyDemographicInsights') {
      const ChartElement = document.getElementById('exportMoreInsightsWorldMap');
      ChartElement.style.display = 'block';
      copyToClipboard(value, setValue);
      ChartElement.style.display = 'none';
    } else {
      copyToClipboard(value, setValue);
    }
  };

  useEffect(() => {
    dispatch(getActivityLevel({ period, department_id: department, subdepartment_id: subDepartment, compare_previous: compareprevious, country_id: location }));
  }, [dispatch, period, department, subDepartment, compareprevious, location]);

  return (
    <>
      {isLoading && (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      )}
      {/* {data === undefined ? (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      ) : null} */}
      <BreadCrumbContainer>
        <LocationPath styles={{ margin: 0 }} multi paths={paths} pathName={t("analytics")} />
        <StyledDiv>
          <NewStyledSelect onChange={handleChange} isRequired />
          <GlobalFilters
            period={period}
            onPeriodChange={onPeriodChange}
            department={department}
            onSubDeptChange={onSubDeptChange}
            onSelectChange={onSelectChange}
            onLocationChange={onLocationChange}
          />
        </StyledDiv>
      </BreadCrumbContainer>
      {/* <FiltersWrapper>
        <GlobalFilters period={period} onPeriodChange={onPeriodChange} onSelectChange={onSelectChange} />
      </FiltersWrapper> */}
      <MobileMargin />
      <div className="copyFullImage">
        <div className="copyChartElement">
          <ActivityLevelCard period={period} setCompareprevious={setCompareprevious} />
        </div>
        <div id="moreInsightsWorldMap">
          <MoreInsightsWorldMap />
        </div>

        <div id="exportMoreInsightsWorldMap" className="copyDemographicInsights" style={{ display: 'none' }}>
          <ExportMoreInsightsWorldMap />
        </div>
      </div>
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;

const HoverWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background: #ffffff;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const MobileMargin = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 0;

  @media screen and (max-width: 715px) {
    margin-bottom: 30px;
  }
`;

const FiltersWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 18px;

  @media screen and (max-width: 715px) {
    top: 60px;
    left: 25px;
  }
`;

export default memo(ActivityLevelPage);
