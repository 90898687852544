import React, { memo, useEffect, useState } from 'react';

import { Divider } from 'antd';

import FullCard from '../../../Components/fullCard';
import SingleViewPieWithDescription from '../../../Components/singleViewPieWithDescription';
import CardHeader from '../../../Components/cardHeader';
import api from '../../../Util/api';
import { useTranslation } from 'react-i18next';

const LeadingIssueCard = ({ period, departmentId, subDepartmentId, location, setLoading }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState({});
  const [responseType, setResponseType] = useState(false);
  const [compareprevious, setCompareprevious] = useState(0);
  const [loader, setLoader] = useState(0);

  const onSelectResponseChange = (id) => {
    setResponseType(id);
  };
  const getPrev = (filters) => {
    setCompareprevious(filters.compare_previous);
  };

  const noDataMapping = (t) => ({
    true: t("noPositiveResponses"),
    false : t("noNegativeResponses"),
    all: t("noData")
  })

  useEffect(() => {
    const payload = {
      period,
      department_id: departmentId,
      subdepartment_id: subDepartmentId,
      compare_previous: compareprevious,
      positive: responseType,
      country_id: location,
    };

    const getSurveyResponseData = async () => {
      try {
        setLoading((prevState) => prevState + 1);
        setLoader((prevState) => prevState + 1);
        const response = await api.analytics.getLeadingIssues(payload);
        console.log('🚀 ~ file: leadingIssueCard.js ~ line 43 ~ getSurveyResponseData ~ response', response.data.data);
        setResponse(response?.data?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading((prevState) => prevState - 1);
        setLoader((prevState) => prevState - 1);
      }
    };
    getSurveyResponseData();
  }, [period, departmentId, subDepartmentId, compareprevious, responseType, location]);

  return (
    <FullCard>
      <CardHeader
        showResponseType
        onSelectResponseChange={onSelectResponseChange}
        name={t("leadingIssues")}
        noFilters
        noCompare={false}
        onSelect={getPrev}
        customWidth="400px"
        toolTipText={t("leadingIssuesTooltip")}
      />
      <SingleViewPieWithDescription data={response?.current || []} loader={loader} noDataMessage={noDataMapping(t)[responseType]}/>
      {response?.previous && (
        <>
          <Divider />
          <CardHeader
            name={`${t("last")} ${period} ${t("leadingIssues")}`}
            noFilters
            customWidth="400px"
            toolTipText={t("leadingIssuesTooltip")}
          />

          <SingleViewPieWithDescription data={response?.previous || []} loader={loader} noDataMessage={noDataMapping(t)[responseType]}/>
        </>
      )}
    </FullCard>
  );
};

export default memo(LeadingIssueCard);
