import React, { useState } from 'react';

import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Radio, Select, Tooltip } from 'antd';

import FullCard from '../../../Components/fullCard';
import HeaderName from '../../../Components/headerName';
import {
  BrowsFiles,
  ButtonsWrapper,
  StyledSelect,
  TextAreaCounterWrapper,
  TextAreaErrorPosition,
  UploadContent,
  UploadDashedBorder,
} from './createSurveyPage';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const SurveyForm = ({
  departments,
  employeeList,
  getRootProps,
  getInputProps,
  isDragActive,
  isDragReject,
  thumbs,
  fileError,
  checkAllFieldFilled,
  goToDashboard,
  form,
  files,
  setStep,
  isEdit,
  uploadedLogo,
}) => {
  console.log('🚀 ~ employeeList:', employeeList);

  const { t } = useTranslation();
  const options = [
    { label: 'Department', value: 'Department' },
    { label: 'Individual Employees', value: 'Individual' },
  ];

  const [type, setType] = useState('');
  return (
    <FullCard>
      <HeaderName>{t('createHeartbeat')}</HeaderName>
      <Form.Item
        name="type"
        label={t('sendSurveyTo')}
        rules={[{ required: true, message: t('pleaseSelectDepartment') }]}
      >
        <Radio.Group
          value={type}
          options={options}
          defaultValue="Apple"
          onChange={(e) => {
            setType(e.target.value);
          }}
        />
      </Form.Item>
      {type === 'Department' ? (
        <Form.Item
          name="send_to"
          label={t('selectDept')}
          rules={[{ required: true, message: t('pleaseSelectDepartment') }]}
        >
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder={t('pleaseSelectTo')}
            style={{ maxWidth: 500 }}
          >
            <Option value="0">{t('all')}</Option>
            {departments?.map(({ id, name }) => (
              <Option value={id}>{name}</Option>
            ))}
          </StyledSelect>
        </Form.Item>
      ) : type === 'Individual' ? (
        <Form.Item
          name="employee_ids"
          label={t('selectEmployees')}
          rules={[{ required: true, message: t('pleaseSelectEmployees') }]}
        >
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder={t('pleaseSelectTo')}
            showSearch
            labelInValue
            mode="multiple"
            style={{ maxWidth: 500 }}
          >
            {employeeList?.map(({ id, first_name, last_name, email }) => (
              <Option value={`${first_name} ${last_name}--${id}`}>
                {first_name} {last_name} ({email})
              </Option>
            ))}
          </StyledSelect>
        </Form.Item>
      ) : null}

      <Form.Item
        name="survey_name"
        label={t('surveyName')}
        rules={[{ required: true, message: t('pleaseSelectYourSurveyName') }]}
      >
        <Input
          name="surveyName"
          placeholder={t('enterSurveyName')}
          style={{ maxWidth: 500 }}
          maxLength={100}
          showCount
        />
      </Form.Item>

      <TextAreaErrorPosition>
        <Form.Item
          name="description"
          label={t('description')}
          rules={[{ required: true, message: t('pleaseWriteSomeDescription') }]}
        >
          <TextAreaCounterWrapper
            name="description"
            placeholder={t('enterADescription')}
            style={{ maxWidth: 500 }}
            maxLength={500}
            showCount
          />
        </Form.Item>
      </TextAreaErrorPosition>

      <Form.Item name="logo" label={t('uploadLogo')} style={{ maxWidth: 500 }}>
        <UploadDashedBorder {...getRootProps()} isDragActive={isDragActive} isDragReject={isDragReject}>
          <input {...getInputProps()} />
          <UploadContent>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CloudUploadOutlined style={{ fontSize: '40px', color: '#575D7A', marginRight: '7px' }} />
              <div style={{ color: '#575D7A', fontSize: '16px' }}>{t('dragDrop')}</div>
            </div>
            <div style={{ color: '#575D7A', fontSize: '16px' }}>{t('or')}</div>
            <BrowsFiles>{t('browseFiles')}</BrowsFiles>
          </UploadContent>
        </UploadDashedBorder>
        <span
          style={{
            marginTop: 5,
            color: '#CCCCCC',
            display: 'block',
          }}
        >
          {t('logoDescription')}
        </span>
        {isEdit && uploadedLogo ? uploadedLogo : thumbs}
        {fileError && <p style={{ color: 'red' }}>{t('pleaseUploadLogo')}</p>}
      </Form.Item>

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <ButtonsWrapper>
          <Button style={{ marginRight: 10 }} onClick={goToDashboard}>
            {t('cancel')}
          </Button>
          <Tooltip
            title={
              checkAllFieldFilled(form.getFieldValue(), files?.length) ? t('pleaseFillAllFieldsToGotoNextStep') : null
            }
          >
            <Button
              type="primary"
              disabled={checkAllFieldFilled(form.getFieldValue(), files?.length)}
              onClick={() => setStep(1)}
            >
              {t('next')}
            </Button>
          </Tooltip>
        </ButtonsWrapper>
      </div>
    </FullCard>
  );
};

export default SurveyForm;
