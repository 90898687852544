import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

import PeriodFilter from './periodFilter';
import { useSelector } from 'react-redux';

const { Option } = Select;

const GlobalFilters = ({ period, onPeriodChange,department, onSelectChange,onSubDeptChange, onLocationChange }) => {
  const selectorOptions = useSelector((store) => store.company.departments);
  const locationOptions = useSelector((store) => store.company.locations);
  const [subDepartmentList,setSubDeptList] =useState([])
  useEffect(() => {
    if(department){
      let subDepts = selectorOptions.find(i => i.id === department)?.subdepartments || [];
      setSubDeptList(subDepts);
    }
  }, [department])
  const { t } = useTranslation();
  return (
    <Wrapper>
      <PeriodFilter period={period} onClick={onPeriodChange} />
      <StyledSelect
        getPopupContainer={(trigger) => trigger.parentElement}
        defaultValue="0"
        bordered={false}
        onChange={onSelectChange}
      >
        <Option value="0">{t("allDepartments")}</Option>
        {selectorOptions?.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </StyledSelect>
      <StyledSelect
        getPopupContainer={(trigger) => trigger.parentElement}
        defaultValue="0"
        bordered={false}
        onChange={onSubDeptChange}
      >
        <Option value="0">{t("allSubDepartments")}</Option>
        {subDepartmentList?.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </StyledSelect>
      {/* <StyledSelect
        getPopupContainer={(trigger) => trigger.parentElement}
        defaultValue="0"
        bordered={false}
        onChange={onLocationChange}
      >
        <Option value="0">{t("allLocations")}</Option>
        {locationOptions?.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </StyledSelect> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const StyledSelect = styled(Select)`
  font-size: 11px;
  display: inline-block;
  margin-left: 10px;

  .ant-select-borderless {
    border: none !important;
  }

  .ant-select-selector {
    width: 130px !important;
    height: 38px !important;
    padding: 0 !important;
    padding-left: 10px !important;
    border: 1px solid #cdcdcd !important;
    color: #575d7a;
  }

  .ant-select-selection-item {
    padding-right: 24px !important;
  }
`;

export default GlobalFilters;
